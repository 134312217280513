<template>
  <div class="reopen">
    <header>重开柜门</header>
    <!--open-->
    <div class="cont" v-if="open">
      <img class="p1" src="../assets/imgs/reopen-success.png" />
      <span class="txt"> 您最近一次在{{ vinId }}上的购物时间为： </span>
      <span class="txt">{{ crateTime }}</span>
      <span class="txt">
        您可在
        <span class="reopenTxt">{{ beforeTime }}</span>
        前重开柜门
      </span>
      <div class="btns">
        <div class="btn toIndex" @click="toIndex">返回首页</div>
        <div class="btn active" @click="reopen">重开柜门</div>
      </div>
    </div>
    <!--notOpen-->
    <div class="cont" v-else>
      <img class="p1" src="../assets/imgs/reopen-warning.png" />
      <span class="txt forbidden">
        您近期未在该车辆中购物，或上一笔订单已超出重开时间。当前不允许重开柜门，如有疑问请联系客服
        <a class="phone" href="tel:400-666-7876">400-666-7876</a>
      </span>

      <div class="btn toIndex" @click="toIndex">返回首页</div>
    </div>
  </div>
</template>

<script>
import { smartCabinetReopen, checkReopenDoorRight } from "@/api/api";
export default {
  data() {
    return {
      userInfo: {},
      open: true,
      orderCode: "",
      beforeTime: "",
      crateTime: "",
      vinId: "",
    };
  },
  mounted() {
    const vin = this.$router.history.current.query.vin ?? "LHT5Y2A4XLC4WA003";
    checkReopenDoorRight({ vin }).then((res) => {
      this.open = res.reopen;
      this.orderCode = res.orderCode ?? "";
      this.beforeTime = res.beforeTime ?? "";
      this.crateTime = res.crateTime ?? "";
      this.vinId = res.vin_id ?? "";
      this.cabinCode = res.cabinCode ?? "";
    });
  },
  beforeDestroy() {},
  methods: {
    toIndex() {
      window.location.href = "neolixjs://webview?url=home";
    },
    reopen() {
      smartCabinetReopen({ orderCode: this.orderCode }).then(() => {
        this.$router.push({
          path: "/result",
          replace: true,
          query: { orderCode: this.orderCode, cabinCode: this.cabinCode },
        });
      });

      // Request(
      //   API.smartCabinetReopen,
      //   {
      //     method: "POST",
      //     data: {
      //       orderCode: this.orderID || "",
      //     },
      //   },
      //   (res) => {
      //     wx.redirectTo({
      //       url: `../../pages/result?state=${1}&orderID=${
      //         this.orderID
      //       }&originReopen=1`,
      //     });
      //   }
      // );
    },
    checkReopenDoorRight() {
      // Request(API.checkReopenDoorRight, {
      //   method: 'POST',
      //   // loading:1,
      //   data: {
      //     type: 0,//微信传0
      //     vin:  this.$parent.globalData.vin,
      //     token: this.userInfo.token,
      //   }
      // }, res => {
      //   console.log(res);
      //   this.open = res.reopen;
      //   this.orderID = res.orderCode;
      //   this.beforeTime = res.beforeTime || '';
      //   this.crateTime = res.crateTime || '';
      //   this.vinId = res.vin_id || '';
      //   this.$apply();
      // });
    },
  },
};
</script>

<style scoped lang="scss">
[data-theme="light"] {
  .result {
    background: white;
  }
  .active {
    &:active {
      background: #d95234 !important;
      color: #d9d9d9 !important;
    }
  }
  .toIndex {
    &:active {
      background: #c8c9cd !important;
    }
  }
}
.reopen {
  height: 100%;
  overflow: hidden;
  background: white;
  @include background_color("reopen_bg");
  header {
    font-weight: bold;
    height: 1.16rem;
    font-size: 0.48rem;
    font-weight: bold;
    color: #181818;
    @include font_color("title_color");
    line-height: 1.16rem;
    padding-left: 0.48rem;
    background: white;
    @include background_color("title_bg");
    top: 0;
    left: 0;
  }
  .cont {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 1.6rem 0.48rem;

    .p1 {
      width: 4.5rem;
      height: 4.5rem;
      margin-bottom: 0.3rem;
    }
    .txt {
      font-weight: bold;
      font-size: 0.32rem;
      color: #222;
      @include font_color("title_color");
      margin-bottom: 0.12rem;
      .phone {
        color: #ff613e;
        text-decoration: none;
      }
      .reopenTxt {
        color: #ff613e;
      }
      &.forbidden {
        text-align: center;
      }
    }
    .btns {
      width: 100%;
      // margin-top: 0.6rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.3rem;
      color: #000000;
      @include font_color("result_btn_color");
      .btn {
        width: 3.15rem;
        height: 0.92rem;
        &.active {
          color: white;
          background: #ff613e;
        }
      }
    }
    .btn {
      margin-top: 0.6rem;
      width: 4.7rem;
      height: 0.88rem;
      border-radius: 0.54rem;
      background: #ecedf2;
      font-size: 0.32rem;
      color: #000000;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      @include font_color("result_btn_color");
      @include background_color("result_btn_bg");
      &.active {
        background: #f6c24a;
        border: none;
      }
    }
  }
}
</style>
